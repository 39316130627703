import axios from "axios";
import Vue from 'vue'


let instanceToken = axios.create({
  baseURL: process.env.VUE_APP_URL,
});

let instanceFileUpload = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: { "Content-Type": "multipart/form-data" },
});
// Alter defaults after instance has been created
instanceFileUpload.interceptors.request.use(
  function (config) {
    const token = Vue.$keycloak.idToken

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a request interceptor for tokenized requests
instanceToken.interceptors.request.use(
  (config) => {
    const token = Vue.$keycloak.idToken

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Commands
instanceToken.interceptors.response.use((response) => {
  const originalRequest = response.config;
  if (response.status == 280) {
    // mark it as a rety to prevent an endless token refresh loop
    originalRequest._retry = true;
    switch (response.data.cmd) {
      case "REFRESH_TOKENS":
        return Vue.$keycloak.updateToken(-1).then(() => {
          location.replace('/settings')
          return Promise.resolve(axios(originalRequest));
        }).catch((err) => {
          console.log("ERROR : Auth");
          Promise.reject(err)
        });
      default:
        break;
    }
  }
  return Promise.reject(response);
})

export default {
  instanceToken: instanceToken,
  instanceFileUpload: instanceFileUpload,
};